<template>
  <div
    class="hidden lg:flex h-full items-center justify-between bg-surface-10 border-l border-b border-surface-60 px-6 sticky z-20"
  >
    <span class="s1 m lg:d py-6">{{ title }}</span>
  </div>
</template>

<script setup lang="ts">
import { ref, watch, onMounted } from "vue";

const route = useRoute();
const title = ref("");

const props = defineProps({
  actionAreaVisible: {
    type: Boolean,
    default: false,
  },
});

const updateTitle = (newTitle: string) => {
  title.value = newTitle;
};

onMounted(() => {
  updateTitle(route.meta.title?.toString());
});

watch([() => route.meta.title], ([newTitle]) => {
  updateTitle(newTitle.toString());
});
</script>
