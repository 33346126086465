export type Navigation = {
  name: string;
  key?: string;
  path?: string;
  icon: string;
  position: "top" | "bottom";
  count?: number;
  onClick?: {
    type: Function;
  };
  visible: boolean;
};

export const getNavigations = () => {
  const navigations: Navigation[] = [
    {
      icon: "icon-report",
      name: "Genel Bakış",
      path: routes.overview.path,
      position: "top",
      visible: true,
    },
    {
      icon: "icon-file",
      name: "Kaynaklar",
      path: routes.resources.path,
      position: "top",
      visible: false,
    },
    {
      icon: "icon-logout",
      name: "Güvenli Çıkış",
      key: "logout",
      position: "bottom",
      visible: true,
    },
  ];
  return navigations.filter((nav) => nav.visible);
};
