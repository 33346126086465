<template>
  <header
    class="fixed left-0 right-0 h-20 border-b border-surface-60 bg-surface-10 grid items-center content-center px-5 lg:px-0 grid-cols-3 lg:grid-cols-[224px,1fr] z-10"
  >
    <div class="grid w-fit lg:hidden" @click="onOpenNavbarMenu">
      <span class="icon-menu text-2xl cursor-pointer" />
    </div>
    <div class="grid h-20">
      <h1 class="flex justify-center self-center">
        <Img
          src="/images/heltia-insights-logo.png"
          alt="Heltia Logo"
          width="128"
          @click="$router.push('/')"
          class="cursor-pointer"
        />
      </h1>
    </div>
    <WebExpHeader :actionAreaVisible="true" />
  </header>
  <main id="app" class="min-h-screen w-full bg-surface-base flex flex-col">
    <div>
      <WebExpNavbar />
    </div>

    <div class="overflow-auto lg:ml-[224px] pt-20 lg:pt-0">
      <slot />
    </div>
  </main>
</template>
<script setup lang="ts">
import { useUiStore } from "~/store/ui";
const uiStore = useUiStore();
const onOpenNavbarMenu = () => {
  uiStore.openNavbarMenu();
};
</script>
