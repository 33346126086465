<template>
  <Confirmation
    title="Çıkış Yap"
    :text="`${userEmail} adresiyle çıkış yapmak üzeresiniz`"
    confirmActionText="Çıkış yapın"
    closeActionText="Vazgeçin"
    :onCancel="onClose"
    :onConfirm="handleLogoutConfirm"
    :isPopUp="true"
    :alert="true"
    :open="open"
  />
</template>
<script setup lang="ts">
import { useAuthStore } from "~/store/auth";
import { onBeforeRouteLeave } from "vue-router";
import Tracking from "~/utils/tracking";
import { useUiStore } from "~/store/ui";
import { useUserStore } from "~/store/user";
import { useStatusStore } from "~/store/status";
const props = defineProps({
  open: {
    type: Boolean,
    required: true,
  },
  onClose: {
    type: Function,
    required: true,
  },
});

const authStore = useAuthStore();
const userStore = useUserStore();
const statusStore = useStatusStore();
const uiStore = useUiStore();
const tracking = Tracking.getInstance();

const userEmail = computed(() => userStore.insightsProfile?.email);

const handleLogoutConfirm = async () => {
  await authStore.logoutApiCall();
  await authStore.logout();

  uiStore.$reset();
  userStore.$reset();
  statusStore.$reset();
  tracking.loggedOut();
  props.onClose();
  navigateTo(routes.signin.path);
};

onBeforeRouteLeave((to, from, next) => {
  if (!props.open) {
    next();
  } else {
    next(false);
  }
});
</script>
